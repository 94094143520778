/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                // handle links with @href started with '#' only
                $(document).on('click', 'a[href^="#"]', function(e) {
                    // target element id
                    var id = $(this).attr('href');

                    // target element
                    var $id = $(id);
                    if ($id.length === 0) {
                        return;
                    }

                    // prevent standard hash navigation (avoid blinking in IE)
                    e.preventDefault();

                    // top position relative to the document
                    var pos = $id.offset().top-150;

                    // animated top scrolling
                    $('body, html').animate({scrollTop: pos});
                });

                //*Mobile Menu*//
                $( ".c-header__toggle" ).click(function(e) {
                    e.preventDefault();

                    $('.c-header__mobile').animate({width: 'toggle'});
                });

                // for the mobile animation

                $(".c-header__toggle").click(function(){
                    $(this).toggleClass("is-active");
                });


                var viewportWidth2 = $(window).width(),
                headerHeight = $('.c-header','.c-logo').outerHeight(true),
                logoTop = $('.c-logo').offset().top + (0),
                adminbarHeight = $('#wpadminbar').outerHeight(true);

                if (viewportWidth2 >= 1024) {
                    $(window).scroll(function(){
                        var scrollY = $(this).scrollTop();


                        // Sticky Menu
                        if (scrollY >= logoTop) {
                            $('.c-logo').addClass('scrolled');

                        } else {
                            $('.c-logo').removeClass('scrolled');
                        }

                    });
                }

                $(function () {
                    var children = $('.m-nav__list > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                    $('<span class="drop-menu">+</span>').insertAfter(children);
                    $('.m-nav__list .drop-menu').click(function (e) {
                        $(this).next().slideToggle(300);
                        return false;
                    });
                });

                $(function () {
                    var children = $('.c-nav__list > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                    $('<span class="drop-menu">▼</span>').insertAfter(children);
                    $('.c-nav__list .drop-menu').click(function (e) {
                        $(this).next().slideToggle(300);
                        return false;
                    });
                });

                /// Contact form 7 redirection
                document.addEventListener( 'wpcf7mailsent', function( event ) {
    location = 'http://focalflight.com/thank-you/';}, true );
/////////////////////////////////

var drawingManager;
var selectedShape;
var geocoder;
var map;
var markers = [];

function clearSelection() {
    if (selectedShape) {
        pointsToText();
        selectedShape.setEditable(false);
        selectedShape = null;
    }
}
function setSelection(shape) {
    clearSelection();
    selectedShape = shape;
    shape.setEditable(true);
}
function deleteSelectedShape() {
    if (selectedShape) {
        selectedShape.setMap(null);
    }
}
function Select_poly(){
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    drawPoly = true;
}

function Disable_poly(){
    drawingManager.setDrawingMode(null);
    drawPoly = false;
}
function HandControl(controlDiv, map) {
    var control = this;
    controlDiv.style.padding = '5px';

    var goHandUI = document.createElement('div');
    goHandUI.style.cursor = 'pointer';
    goHandUI.style.height = '38px';
    goHandUI.style.width = '38px';
    controlDiv.appendChild(goHandUI);

    var handImg = document.createElement("IMG");
    handImg.setAttribute("src", "/app/themes/sage/dist/images/stop_drowing_icon.png");
    goHandUI.appendChild(handImg);

    google.maps.event.addDomListener(goHandUI, 'click', function() {
        Disable_poly();
    });
}
function PolyControl(controlDiv, map) {
    var control = this;
    controlDiv.style.padding = '5px';

    var goPolyUI = document.createElement('div');
    goPolyUI.style.cursor = 'pointer';
    goPolyUI.style.height = '38px';
    goPolyUI.style.width = '38px';
    controlDiv.appendChild(goPolyUI);

    var polyImg = document.createElement("IMG");
    polyImg.setAttribute("src", "/app/themes/sage/dist/images/drow_icon.png");
    goPolyUI.appendChild(polyImg);

    google.maps.event.addDomListener(goPolyUI, 'click', function() {
        Select_poly();
    });

}
function RemoveControl(controlDiv, map) {
    var control = this;
    controlDiv.style.padding = '5px';

    // Set CSS for the control border
    var goRemoveUI = document.createElement('div');
    goRemoveUI.style.cursor = 'pointer';
    goRemoveUI.style.height = '38px';
    goRemoveUI.style.width = '38px';
    controlDiv.appendChild(goRemoveUI);

    var removeImg = document.createElement("IMG");
    removeImg.setAttribute("src", "/app/themes/sage/dist/images/remove_icon.png");
    goRemoveUI.appendChild(removeImg);

    google.maps.event.addDomListener(goRemoveUI, 'click', function() {
        deleteSelectedShape();
    });

}
function initialize() {
    map = new google.maps.Map(document.getElementById('map-canvas1'), {
        zoom: 9,
center: new google.maps.LatLng(34.161376 , -118.819051),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true,
        mapTypeControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.TOP_LEFT
        },
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT
        }
    });

    var polyOptions = {
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
        strokeWeight: 4,
        fillOpacity: 0.45,
        editable: false
    };

    var handControlDiv = document.createElement('div');
    var handControl = new HandControl(handControlDiv, map);
    handControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(handControlDiv);


    var polyControlDiv = document.createElement('div');
    var polyControl = new PolyControl(polyControlDiv, map);
    polyControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(polyControlDiv);


    var removeControlDiv = document.createElement('div');
    var removeControl = new RemoveControl(removeControlDiv, map);
    removeControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(removeControlDiv);

    // Creates a drawing manager attached to the map that allows the user to draw
    // markers, lines, and shapes.
    drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null, //google.maps.drawing.OverlayType.POLYGON,
        markerOptions: {
            draggable: true
        },
        polylineOptions: {
            editable: true
        },
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [

            ]
        },
        polygonOptions: polyOptions,
        map: map,
        drawingControl: false
    });
    geocoder = new google.maps.Geocoder();
    google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
        if (e.type != google.maps.drawing.OverlayType.MARKER) {
            // Switch back to non-drawing mode after drawing a shape.
            drawingManager.setDrawingMode(null);

            // Add an event listener that selects the newly-drawn shape when the user
            // mouses down on it.
            var newShape = e.overlay;
            newShape.type = e.type;
            google.maps.event.addListener(newShape, 'click', function() {
                arr = [];
                arr.push(e);
                pointsToText();
                setSelection(newShape);
            });

            arr = [];
            arr.push(e);
            pointsToText();
            setSelection(newShape);
        }
    });
    google.maps.event.addDomListener(document.getElementById('clear_polygon'), 'click', deleteSelectedShape);
    google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
    google.maps.event.addListener(map, 'click', clearSelection);
    google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', deleteSelectedShape);
}

// Sets the map on all markers in the array.
function setAllMap(map) {
    for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}
// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
    setAllMap(null);
}

function deleteMarkers() {
    clearMarkers();
    markers = [];
}

jQuery('#search_btn').click(function(){
    var address = document.getElementById("search_location").value;
    console.log(address);
    geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);
            map.fitBounds(results[0].geometry.bounds);
            var latlong = "(" + results[0].geometry.location.lat().toFixed(6) + " , " + results[0].geometry.location.lng().toFixed(6) + ")";
            console.log(latlong);
            //map.setZoom(10);
            jQuery('#location').val(address);
        } else {
            alert("Lat and long cannot be found.");
        }
    });
});

jQuery('.wpcf7-submit').click(function(){
    pointsToText();
});

function pointsToText() {
    for (i = 0; i < arr.length; i++) {
        var coordinates = (arr[i].overlay.getPath().getArray());
        console.log(coordinates.join(',\n'));
        jQuery('#map_cordinates').val(coordinates.join(',\n'));
    }
}
jQuery('.wpcf7-submit').click(function(){
    pointsToText();


function pointsToText() {
    for (i = 0; i < arr.length; i++) {
        var coordinates = (arr[i].overlay.getPath().getArray());
        console.log(coordinates.join(',\n'));
        jQuery('#map_cordinates').val(coordinates.join(',\n'));
    }
}
});
//google.maps.event.addDomListener(window, 'load', initialize);




            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
